import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { Nullable } from 'types/common';

const dataDrivenLocalSlice = createSlice({
  name: 'dataDrivenLocalSlice',
  initialState: {
    scannedPubkeys: [] as string[],
    completeBinMessage: null as Nullable<string>,
    lastBufferBinMessage: null as Nullable<string>,
  },
  reducers: {
    addScannedPubkey(draftState, action: PayloadAction<string>) {
      const { payload } = action;
      draftState.scannedPubkeys.push(payload);
    },
    clearScannedPubkeys: draftState => {
      draftState.scannedPubkeys = [];
    },
    setCompleteBinMessage: (draftState, action: PayloadAction<string>) => {
      const { payload } = action;
      draftState.completeBinMessage = payload;
    },
    setLastBufferBinMessage: (draftState, action: PayloadAction<string>) => {
      const { payload } = action;
      draftState.lastBufferBinMessage = payload;
    },
    clearBufferMessages: draftState => {
      draftState.completeBinMessage = null;
      draftState.lastBufferBinMessage = null;
    },
  },
});

const { reducer, actions } = dataDrivenLocalSlice;

export const {
  addScannedPubkey,
  clearScannedPubkeys,
  setCompleteBinMessage,
  setLastBufferBinMessage,
  // clearBufferMessages,
} = actions;

export default reducer;
