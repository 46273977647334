const VIEW_MODE_DATA_DRIVEN = {
  lab: { link: '/production', color: '#212121' },
  'production-lab-automation': {
    link: '/production/scan-to-produce/',
    color: '#212121',
  },
  automationScreens: { link: '/production/ddpf/eol', color: '#212121' },
  // automationScreens: { link: '/production/automation-screen/', color: '#212121' },
  labeling: { link: '/production/labeling-defect/', color: '#212121' },
  collateralsreprint: { link: '/production/collaterals-reprint/', color: '#212121' },
  labelsreprint: { link: '/production/labels-reprint/', color: '#212121' },
  endoflanebufferspace: { link: '/production/ddpf/buffertrolleys', color: '#212121' },
  // endoflanebufferspace: { link: '/production/end-of-lane-buffer-space/', color: '#212121' },
  'buffer-space': { link: '/production/buffer-spaces/completed', color: '#212121' },
  prepack: { link: '/production/ddpf/prepack', color: '#212121' },
  // prepack: { link: '/production/prepack_lab/', color: '#212121' },
  packing: { link: '/production/ddpf/packing', color: '#212121' },
  // packing: { link: '/production/packing-station/', color: '#212121' },
  qualitytests: { link: '/production/quality-tests/ongoing', color: '#212121' },
  palletsmanagement: { link: '/production/pallets-management/new', color: '#212121' },
  palletizeboxes: { link: '/production/palletize-boxes/', color: '#212121' },
};

export default VIEW_MODE_DATA_DRIVEN;
